<template>
  <b-card>
    <div>
      <b-row class="p-1">
        <div v-if="!showSearch">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisa"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input 
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <b-table-simple
        striped
        stacked="md"
        noDataText="Você ainda não possui clientes cadastrados."
        class="mt-2 responsive"
        id="tableLogs"
        :item="fetchLogSend"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <b-thead class="text-center">
          <b-th>Colaborador</b-th>
          <b-th>Quantidade</b-th>
          <b-th>Canal</b-th>
          <b-th>Data e hora</b-th>
          <b-th>Identificador</b-th>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in logsend">
            <b-td> {{ tr.tenant.company_name }} </b-td>
            <b-td> {{ tr.amount }} </b-td>
            <b-td class="text-center">
              <b-badge variant="success" class="badge-glow"> {{ tr.channel.name }} </b-badge>
            </b-td>
            <b-td> {{ tr.created_at | dateTimeFormat }} </b-td>
            <b-td>
              {{ tr.identifier }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      </b-overlay>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="tableLogs"
          class="mt-1"
        />
    </div>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BButton, BModal, VBModal } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      showSearch: false,

      search: null,

      isLoading: true,

      selected: null,
      options: [
        { value: null, text: "Status" },
        { value: "a", text: "Finalizada" },
        { value: "b", text: "Pendente" },
        { value: "c", text: "Não paga" },
      ],
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  computed: mapState("logsend", ["logsend", "pagination"]),
  methods: {
    fetchLogSend(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("logsend/fetchLogSend", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchLogSend(true, value);
    },
  },
  created() {
    this.fetchLogSend();
  },
   watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchLogSend(true, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
